<template>
  <div>
    <!-- 学员信息 -->
    <stu-info @select="handleStudentSelected" :currlength="curriculumList.length" />

    <div class="margin-top padding bg-white">
      <border-title titleName="转课信息" />
      <div class="margin-top flex aligin-items-center">
        转出课程：
        <el-select v-model="value" placeholder="请选择" size="mini" @change="selChange">
          <el-option v-for="item in curriculumList" :key="item.value" :label="item.curriculum.curriculum_name"
            :value="item.curriculum_id" />
        </el-select>

        <div class="margin-left-50 flex">
          <div class="margin-right-lg" v-if="Curriculums.length > 0">转入课程-{{ Curriculums[0].curriculum_name }}-{{
            Curriculums[0].school_name }}</div>
          <el-button type="primary" size="mini" @click="selectCourses">转入课程</el-button>
          <el-button type="primary" size="mini" @click="selectClass">直接选班</el-button>
        </div>
      </div>
      <div class="no-curriculums" v-show="isShow">
        <i class="el-icon-circle-plus-outline"></i>
        <p>请点击左上方按钮选择报名信息</p>
      </div>
    </div>

    <div class="padding">
      <!-- 转出课程 -->
      <border-title titleName="转出课程" />
      <!-- 办理类型 -->
      <div class="margin-top">
        <span class="margin-right-xl">办理类型：</span>
        <el-radio-group v-model="radio">
          <el-radio :label="3">全部转出</el-radio>
          <el-radio :label="6">部分转出</el-radio>
        </el-radio-group>
        <i class="el-icon-question text-blue el-icon--right"></i>
      </div>
      <div class="margin-top-sm">
        <el-table border :data="orderData" style="width: 100%">
          <el-table-column prop="order_id" label="订单号" />
          <el-table-column prop="" label="经办日期">
            <template slot-scope="scope">
              <span class="">{{ scope.row.add_time | dateTimeFilter('yyyy-MM-dd') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="购买课时">
            <template slot-scope="scope">
              <span class="">{{ scope.row.total_num }}课时</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="有效期">
            <template slot-scope="scope">
              <span class="">{{ scope.row.valid_time | dateTimeFilter('yyyy-MM-dd') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="total_money" label="原价" />
          <!-- <el-table-column prop="order_id" label="优惠"></el-table-column> -->
          <el-table-column prop="pay_money" label="实收" />
          <el-table-column prop="over_hours" label="剩余课时" />
          <el-table-column prop="used_money" label="已用学费" />
          <el-table-column prop="refund_money" label="累计退出金额" />
          <el-table-column prop="outHours" label="本次转出时长" width="150">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.outHours" controls-position="right" :min="0"
                @change="(v)=>{changehours(v,scope.row)}" :max="scope.row.over_hours" style="width: 100px;"
                size="mini" />
            </template>
          </el-table-column>
          <el-table-column prop="outMoney" label="本次转出金额" width="150">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.outMoney" controls-position="right" :min="0"
                @change="(v)=>{changeout(v,scope.row)}" :max="scope.row.over_money" style="width: 110px;" size="mini" />
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-content-flexEnd margin-sm">
          <span class="text-grey">
            转出学费：
            <span class="text-black text-bold">{{ outsTotalPrice }}</span>
            元
          </span>
        </div>
      </div>
      <!-- 转入课程 -->
      <border-title titleName="转入课程" class="margin-top" />
      <div class="margin-top" v-for="item in Curriculums" :key="item.id">
        <div class="border-top bg-z padding flex aligin-items-center justify-content-between">
          <div class="flex aligin-items-center">
            <div>
              <span class="lesson-tag">课</span>
              <span class="margin-left-sm">{{ item.curriculum_name }}</span>
              <span class="margin-left-sm">({{student.FullName}})</span>
              <!-- <span class="margin-left-sm">(高升大)</span> -->
              <span class="class-tag margin-left-sm">班</span>
            </div>
            <div class="margin-left" v-show="!item.class_id">
              <div class="margin-left">
                <el-button type="primary" size="mini" @click="selectClass(item.curriculum_id)">选择班级1</el-button>
              </div>
            </div>
          </div>

          <div>
            <span class="text-grey text-sm">
              共:
              <span class="text-bold text-black text-normal">{{ curriculumItemPrice(item).totalNum }}</span>
              {{ curriculumItemPrice(item).unit }}
            </span>
            <span class="text-grey margin-left text-sm">
              小计:
              <span class="text-bold text-black text-normal">{{ item.total_money | moneyFilter }}</span>
              元
            </span>
            <!-- <i class="el-icon-error margin-left-sm text-red cursor" @click="delCurriculum(item, index)" /> -->
          </div>
        </div>
        <div class="padding border-grey">
          <div class="flex">
            <div class="flex1 border-right-grey">
              <div class="flex aligin-items-center justify-content-between">
                <h6>价格</h6>
                <span class="padding-right">
                  <span class="text-blue text-bold">{{ item.total_money | moneyFilter }}</span>
                  <span class="text-grey text-sm">元</span>
                </span>
              </div>
              <div class="padding-tb flex aligin-items-center">
                <span class="text-grey">收费模式</span>
                <div class="margin-left">
                  <el-select v-model="item.courseModel.pay_mode_type" placeholder="请选择" size="mini">
                    <el-option v-for="charge in item.viewModel.listm" :label="chargeDescComputed(charge).title"
                      :value="charge.id"></el-option>
                  </el-select>
                </div>
                <span class="padding-lr">X</span>
                <div>
                  <el-input-number size="mini" v-model="item.courseModel.pay_num" :min="1"></el-input-number>
                </div>
              </div>
            </div>

            <div class="flex1 padding-left">
              <div class="flex aligin-items-center justify-content-between">
                <div class="flex">
                  <h6 class="margin-right">优惠</h6>
                  <el-button size="mini" @click="createTextbook">新增优惠</el-button>
                </div>
                <div class="padding-right">
                  <span class="text-green text-bold">0.00</span>
                  <span class="text-grey text-sm">元</span>
                </div>
              </div>
              <div class="margin-top-sm">
                <el-select v-model="discountValue" placeholder="请选择" size="mini">
                  <el-option v-for="dis in discountOptions" :key="dis.value" :label="dis.label" :value="dis.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="padding-tb border-top-grey flex aligin-items-center">
            <el-checkbox v-model="item.viewModel.validateTimeDisabled">有效期</el-checkbox>
            <div class="margin-left">
              <el-date-picker value-format="yyyy-MM-dd" v-model="item.courseModel.valid_time" type="date"
                placeholder="选择日期" size="mini" :disabled="!item.viewModel.validateTimeDisabled" />
            </div>
            <div class="margin-left-sm">
              <el-tooltip class="item" effect="dark" content="到达有效期后,剩余课程将无法使用" placement="right"><i
                  class="el-icon-question" /></el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="tip padding-top flex aligin-items-center justify-content-between">
        <div class="flex aligin-items-center tip-item flex1">
          <div class="text-grey">对内备注</div>
          <div class="margin-left tip-item-input">
            <el-input v-model="form.neworder.order_in_remark" placeholder="该备注只有内部操作人员可见" size="mini" />
          </div>
        </div>
        <div class="flex aligin-items-center tip-item flex1">
          <div class="text-grey">对外备注</div>
          <div class="margin-left tip-item-input">
            <el-input v-model="form.neworder.order_outer_remak" placeholder="该备注将会被打印且会在校宝家中显示" size="mini" />
          </div>
        </div>
      </div>
    </div>

    <!-- 支付信息 -->
    <pay-info :totalPrice="form.neworder.order_price" :AllBalance="overRecharge" @change="payInfoChange"
      :isRefound="isRefound" :isAddBalance="false" />
    <!-- 订单标签 -->
    <order-tag @change="orderChange" />
    <!-- 经办信息 -->
    <handle-info :isSales="false" @change="handelChange" />

    <div class="sign-up-bottom padding">
      <div class="sign-up-bottom-btn">
        <el-button type="primary" size="medium" @click="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import StuInfo from '../components/stuInfo.vue';
  import HandleInfo from '../components/handleInfo.vue';
  import OrderTag from '../components/orderTag.vue';
  import PayInfo from '../components/payInfo.vue';
  export default {
    components: {
      StuInfo,
      OrderTag,
      HandleInfo,
      PayInfo
    },
    data() {
      return {
        value: '',
        isShow: false,
        radio: 3,
        chargingModes: [],
        curriculumList: [],
        orderData: [],
        student: {},
        form: {
          student_id: 0,
          outs: [],
          neworder: {
            Curriculums: [],
            collections: [],
            order_type: 6, // 6 转课
            student_id: 0,
            IncreaseBalance: 0,
            UsedBalance: 0,
            // source: 0, // 订单来源0 = 未知, 1 = 销售系统, 2 = 线上办理, 3 = 线下办理, 4 = 导入 (导入)
            // order_title: '',
            // order_gid: '',
            // order_no: '',
            // outer_order_no: '',
            // order_body: '',
            // order_number: 0,
            order_price: 0,
            // order_discount: 0, // 折扣
            order_in_remark: '',
            order_outer_remak: '',
            order_tags: '',
            school_id: 0,
            sale_user: 0,
            handle_school: 0,
            handle_date: '',
            // order_user_department: 0,
            // phone: '',
            // relation_order_id: 0,
            handle_user: 0,
            sale_source: 0
            // body: '',
          }
        },
        Curriculums: [],
        overRecharge: 0, // 余额
        discountValue: '', // 优惠
        discountOptions: [
          {
            value: '1',
            label: '营销活动(无)'
          }
        ],

        curriculumItemDefault: {
          type: 0, // 订单课程操作类型0 = 新报 (新报), 1 = 续费 (续费), 2 = 扩科 (扩科), 3 = 无 (无)
          actives: [],
          Incidentals: [],
          curriculum_id: '', // 课程id
          class_id: '', // 班级id
          //收费模式
          pay_mode_type: '', // 收费模式0 = 未知, 1 = 按课时收费  2 = 按时间收费 3 = 按期收费
          pay_mode: '', // 收费模式id
          pay_model_num: 1, // 收费模式数量
          pay_model_price: 0, // 收费模式单价
          //购买数量
          pay_num: 1,

          //有效期
          valid_time: '',
          order_type: 1,
          pay_mode_time_slot: 0,
          use_discount: -1,
          use_discount_money: 0,
          //当前项总金额
          total_money: 0,
          //学员id
          student_id: ''
        },
        chargeTypes: ['', '课时', '时间', '期'],
        //支付信息
        payInfoList: [],
        typeOptions: [
          {
            name: '新报',
            value: 0
          },
          {
            name: '续费',
            value: 1
          },
          {
            name: '扩科',
            value: 2
          },
          {
            name: '无',
            value: 3
          }
        ],
        isRefound: false, // 是否退费
        outsTotalPrice: 0
      };
    },
    created() { },
    mounted() { },
    computed: {
      //收费模式下拉框的title
      chargeDescComputed() {
        return (charge) => {
          let charge_type = charge.charge_type;
          let unit = this.chargeTypes[charge_type];
          let title = `${charge.num}${unit} = ${charge.money}元`;
          return {
            title,
          };
        };
      },
      //课时、费用信息
      // curriculumItemPrice() {
      //   return item => {
      //     let chargeItem = item.viewModel.listm.find(t => t.charge_type === item.courseModel.pay_mode_type);
      //     item.total_money = item.courseModel.pay_num * chargeItem.money;
      //     item.courseModel.pay_mode = item.courseModel.listm.find(t => t.charge_type === item.courseModel.pay_mode_type).id;
      //     // console.log(item.courseModel);
      //     return {
      //       unit: this.chargeTypes[item.courseModel.pay_mode_type],
      //       totalNum: chargeItem.num * item.courseModel.pay_num
      //     };
      //   };
      // }
      curriculumItemPrice() {
        return (item) => {
          try {
            let chargeItem = item.viewModel.listm.find(
              (t) => t.id === item.courseModel.pay_mode_type
            );
            chargeItem = chargeItem || item.viewModel.listm[0];
            item.total_money = item.courseModel.pay_num * chargeItem.money;

            let discountTotal = this.calcuPersentPrice(item);
            item.present_money = item.total_money - discountTotal;

            item.courseModel.pay_mode = chargeItem.id;
            return {
              unit: this.chargeTypes[item.courseModel.pay_mode_type],
              totalNum: chargeItem.num * item.courseModel.pay_num,
            };
          } catch (err) {
            return {
              unit: 0,
              totalNum: 0,
            };
          }
        };
      },
    },
    watch: {
      Curriculums: {
        deep: true,
        handler() {
          this.changeTotalPrice();
        }
      },
      'Curriculums.length': {
        handler() {
          this.changeTotalPrice();
        }
      },
      'form.outs': {
        deep: true,
        handler(val) {
          console.log(val);
          this.outsTotalPrice = 0;
          val.map(t => {
            this.outsTotalPrice += t.outMoney;
          });
        }
      },
      'form.neworder.order_price': {
        deep: true,
        handler(val) {
          if (this.outsTotalPrice < val) this.isRefound = true;
        }
      }
    },
    methods: {
      changehours(v, item) {
        var total = (v * item.price).toFixed(2);
        this.outsTotalPrice = total;

        item.outMoney = total;
        this.changeTotalPrice()
      },
      changeout(v, item) {
        var hours = (v / item.price).toFixed(2);
        this.outsTotalPrice = v;
        item.outHours = hours;
        this.changeTotalPrice()
        this.$forceUpdate()
      },
      //选择了学员
      handleStudentSelected(student) {

        if (student) {
          this.student = student;
          this.isShow = false;
          this.form.student_id = student.id;
          this.form.neworder.student_id = student.id;
          this.API.GetStudentOrderCurriculums(student.id).then(res => {
            this.curriculumList = res.data;
          });
          this.API.GetBalance(student.id).then(res => {
            this.overRecharge = res.data.toFixed(2) * 1;
          });
        }
      },
      // 选择转出课程
      selChange(curriculum_id) {
        this.API.GetOrderCurriculums(this.form.student_id, curriculum_id).then(res => {
          let arr = res.data;
          // [
          //   {
          //     order_curriculum_id: 0, // 转出订单课程id
          //     outHours: 0, // 转出课时
          //     outMoney: 0 //  转出金额
          //   }
          // ],
          arr.map(t => {
            t.order_curriculum_id = t.id;
            t.outHours = t.over_hours;
            t.outMoney = t.total_money;
          });
          this.orderData = arr;
        });
      },
      // 日期选择
      handelChange(e) {
        this.form.neworder.handle_date = e.handle_date;
        this.form.neworder.handle_user = e.handle_user;
        this.form.neworder.sale_user = e.sale_user;
      },
      // 订单标签选择
      orderChange(e) {
        this.form.neworder.order_tags = e.join(',');
      },
      // 支付信息
      payInfoChange(payInfoList) {
        let { payList, handle_school, IncreaseBalance, UsedBalance } = payInfoList;
        this.payInfoList = payList;
        this.form.neworder = Object.assign(this.form.neworder, {
          handle_school,
          IncreaseBalance,
          UsedBalance
        });
      },
      // 选择转入课程
      selectCourses() {
        this.$Dialog.ChooseCoursesSingle().then(res => {
          let arr = [];
          arr.push(res);
          arr.forEach(item => {
            this.Curriculums = [{
              curriculum_name: item.curriculum_name,
              curriculum_id: item.id,
              classModel: {},
              actives: [],
              courseModel: Object.assign(
                {
                  ...this.curriculumItemDefault,
                  pay_num: 1,
                  valid_time: '',
                  pay_mode_type: item.listm[0].id,
                  pay_mode: item.listm[0].id
                },
                item
              ),
              viewModel: {
                validateTimeDisabled: false,
                listm: item.listm
              }
            }]
          });
        });
      },
      // 计算金额
      changeTotalPrice() {
        var _this = this;
        setTimeout(() => {

          this.form.neworder.order_price = this.Curriculums.reduce((total, currentValue) => {
            let count = currentValue.total_money || 0;
            let outMoney = 0;
            _this.orderData.map(t => {
              outMoney += t.outMoney;
            });
            // if (total + count - outMoney) this.isRefound = true;  

            setTimeout(() => {
              if ((total + count - outMoney) < 0) _this.isRefound = true; else _this.isRefound = false;
              _this.$forceUpdate();
            }, 0)
            return Math.abs(total + count - outMoney);
          }, 0);
        }, 1);
      },
      // 选择转入班级1
      selectClass() {
        debugger
        let cids = this.curriculumList.map(o => o.curriculum_id);
        this.$Dialog.ChooseClass({
          bb: cids,
        
          queryList:
          {
            student_id:[2],
          KeyWord:'sss',
            curriculum_id: cids
          }
        }).then(res => {
          if (!res || !res.length) {
            return;
          }
          console.log('chooseClass', res);
          res.forEach(item => {
            this.Curriculums = [
              {
                actives: [],
                Incidentals: [],
                class_id: item.id,
                classModel: item,
                curriculum_id: item.curriculum.id,
                courseModel: {
                  ...this.curriculumItemDefault,
                  ...item.curriculum,
                  pay_mode_type: item.curriculum.listm[0].charge_type,
                  pay_mode: item.curriculum.listm[0].id
                },
                curriculum_name: '',
                viewModel: {
                  validateTimeDisabled: false,
                  listm: item.curriculum.listm
                }
              }
            ]
          });
          this.Curriculums.push(this.Curriculums.pop());
          console.log(this.Curriculums);
        });
      },
      // 新增优惠
      createTextbook() { },
      submit() {
        let submitForm = {
          ...this.form,
          neworder: {
            Curriculums: []
          }
        };
        submitForm.neworder = this.form.neworder;
        submitForm.neworder.collections = this.payInfoList;
        this.orderData.map(t => {
          let obj = {
            order_curriculum_id: t.order_curriculum_id,
            outHours: t.outHours,
            outMoney: t.outMoney
          };
          submitForm.outs.push(obj);
        });
        let msg = '';
        if (!this.Curriculums.length) {
          this.$message.error('转课信息不能为空');
          return;
        }
        let curriculumItemDefault = this.curriculumItemDefault;

        submitForm.neworder.Curriculums = this.Curriculums.map(t => {
          let obj = {
            student_id: submitForm.student_id,
            class_id: t.class_id,
            pay_mode: t.courseModel.listm.find(item => item.charge_type === t.courseModel.pay_mode_type).id
          };
          for (const key in curriculumItemDefault) {
            if (Object.hasOwnProperty.call(curriculumItemDefault, key)) {
              obj[key] = obj[key] || t.courseModel[key] || t[key] || curriculumItemDefault[key];
            }
          }
          return obj;
        });

        if (msg) {
          this.$message.error(msg);
          return;
        }
        console.log(JSON.stringify(submitForm));
        this.API.ConvertClassType(submitForm)
          .then(res => {
            if (res.success) {
              this.$message.success('转课成功');
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped>
  .sign-up-bottom {
    bottom: 0;
    right: 0;
    position: fixed;
  }

  .tip {
    width: 100%;
  }

  .tip-item {
    width: 100%;
  }

  .tip-item-input {
    width: 85%;
  }

  .el-icon-question:hover {
    color: #409eff;
  }

  .no-curriculums {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ccc;
  }
</style>