<template>
  <div class="bg-white margin-top padding">
    <border-title titleName="支付信息" />
    <div
      class="margin-top padding-bottom flex aligin-items-center justify-content-between"
    >
      <div class="flex aligin-items-center">
        <span>经办校区</span>
        <div class="margin-left">
          <el-select v-model="handle_school" placeholder="请选择" size="mini">
            <el-option
              v-for="item in shoolList"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div>
        <span class="text-bold text-black text-lg"> 
          <span v-if="!isRefound">应收总计：</span>
          <span v-else>应退总计：</span>
          <span class="text-lg">{{ totalPrice }}</span>
          元
        </span>
        <p
          v-show="totalPayPrice < totalPrice"
          style="
            margin-top: 10px;
            color: #f15c5c;
            font-size: 16px;
            font-weight: bold;
          "
        >
          欠费：{{ (totalPrice - totalPayPrice - UsedBalance) | moneyFilter }}元
        </p>
        <p
          v-show="totalPayPrice > totalPrice && isAddBalance"
          style="margin-top: 10px"
        >
          增加余额：{{ (totalPayPrice - totalPrice) | moneyFilter }}元
        </p>
      </div>
    </div>

    <div class="border-top-grey padding-tb">
      <div
        class="flex justify-content-flexEnd aligin-items-center"
        v-if="isRecharge"
      >
        <div class="flex aligin-items-center">
          <span>使用余额</span>
          <div class="margin-left">
            <el-input-number
              v-model="UsedBalance"
              controls-position="right"
              @change="usedBalanceChange"
              :min="0"
              :max="AllBalance"
              size="mini"
              :disabled="AllBalance <= 0"
            />
          </div>
        </div>
        <div class="margin-left">
          <span class="text-grey">
            可用：
            <span>{{ AllBalance }}</span>
            元
          </span>
        </div>
      </div>

      <div class="margin-top-sm">
        <div class="flex aligin-items-center bg-z padding-sm">
          <div class="flex-grow3">账户名称</div>
          <div class="flex1 border-left padding-left-sm">对方账户</div>
          <div class="flex1 border-left padding-left-sm">流水/支付单号</div>
          <div v-if="!isRefound" class="flex1 border-left padding-left-sm">
            实收
          </div>
          <div v-else class="flex1 border-left padding-left-sm">实退</div>
          <div
            class="flex1 border-left padding-left-sm"
            v-if="payList.length > 1"
          >
            操作
          </div>
        </div>
        <div
          class="flex aligin-items-center padding-sm border-top-grey"
          v-for="(pay, payIndex) in payList"
          :key="payIndex"
        >
          <div class="flex-grow3">
            <el-select v-model="pay.pay_type" placeholder="请选择" size="mini">
              <el-option
                v-for="item in padInfoOptionsComputed(pay)"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              ></el-option>
            </el-select>
          </div>
          <div class="flex1 padding-left-sm"></div>
          <div class="flex1 padding-left-sm"></div>
          <div class="flex1 padding-left-sm">
            <el-input-number
              v-model="pay.money"
              controls-position="right"
              :min="0"
              size="mini"
            />
          </div>
          <div class="flex1 padding-left-sm" v-if="payList.length > 1">
            <i
              @click="delPayInfo(payIndex)"
              class="el-icon-error cursor margin-left-50 hover-red text-lg"
            ></i>
          </div>
        </div>
        <div
          v-if="payList.length < padInfoOptions.length"
          class="cursor text-center padding-sm border-dashed"
          @click="addPayInfo"
        >
          <i class="el-icon-circle-plus text-blue"></i>
          <span class="margin-left-xs">新增账户</span>
        </div>
        <div class="margin-top flex justify-content-flexEnd">
          <span class="text-bold text-black">
            <span v-if="!isRefound">实收总计：</span>
            <span v-else>实退总计：</span>
            <span>{{ (totalPayPrice + UsedBalance) | moneyFilter }}</span>
            元
          </span>
          <!-- <span class="margin-left-50 text-grey"
            >积分：+{{ totalPayPrice }}分</span
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalPrice: {
      type: Number,
      default: 0,
    },
    // 是否显示余额
    isRecharge: {
      type: Boolean,
      default: true,
    },
    // 可用余额
    AllBalance: {
      type: Number,
      default: 0,
    },
    // 是否退费
    isRefound: {
      type: Boolean,
      default: false,
    },
    // 是否显示增加余额
    isAddBalance: {
      type: Boolean,
      default: true,
    },
    handleSchool: {
      type: Number,
      default: 0,
    },
    collections: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      shoolList: [],
      balanceInput: "0",
      UsedBalance: 0,
      handle_school: "",
      payList: [],
      padInfoOptions: [],
    };
  },
  computed: {
    //实收总计
    totalPayPrice() {
      let total = 0;
      this.payList.forEach((item) => {
        total += item.money;
      });
      return total;
    },
    selectDisabled() {
      return (item) => {
        return this.payList.findIndex((t) => t.pay_type === item.Key) > -1;
      };
    },
    padInfoOptionsComputed() {
      return (item) => {
        let options = [];
        if (item.pay_type) {
          options.push(this.padInfoOptions.find((t) => t.Key == item.pay_type));
        }
        this.padInfoOptions
          .filter((t) => {
            return this.payList.findIndex((t1) => t1.pay_type == t.Key) < 0;
          })
          .forEach((i) => {
            options.push(i);
          });
        return options;
      };
    },
  },
  created() {
    this.getshoolList();
    this.getPayType();
    this.value = this.$store.getters.getUserInfo.school_id;
  },
  watch: {
    handleSchool(val) {
      if (val) {
        this.handle_school = val;
      }
    },
    collections(val) {
      if (val && val.length) {
        console.log('collections change ',val);
        this.payList = val;
      }
    },
    totalPrice(val) {
      if (this.payList.length === 1) {
        this.payList[0].money = val;
        this.payList.push(this.payList.pop());
      }
    },
    AllBalance(val) {
      this.changeValue();
    },
    handle_school() {
      this.changeValue();
    },
    payList: {
      deep: true,
      handler() {
        this.changeValue();
      },
    },
  },
  methods: {
    // 获取支付方式枚举
    getPayType() {
      this.API.SystemGetSettings("PayType", false).then((res) => {
        this.padInfoOptions = res;
        this.$nextTick(() => {
          this.payList.push({
            pay_type: 4,
            money: 0,
          });
        });
      });
    },
    // 新增账户
    addPayInfo() {
      let firstPayType = this.padInfoOptions.filter((t) => {
        return (
          this.payList.findIndex((item) => item.pay_type === t.Key) == -1 &&
          t.Key > 0
        );
      })[0];
      let pay = {
        pay_type: firstPayType && firstPayType.Key,
        money: 0,
      };
      this.payList.push(pay);
      this.changeValue();
    },
    // 删除账户
    delPayInfo(index) {
      let i = this.padInfoOptions.findIndex(
        (item) => item.Key == this.payList[index].pay_type
      );
      this.payList.splice(index, 1);
      this.changeValue();
    },
    // 经办校区
    getshoolList() {
      let obj = {
        PageIndex: 1,
        PageSize: 1000000,
        type: 1,
      };
      this.API.OrganizationList(obj).then((res) => {
        this.shoolList = res.data.rows;
      });
    },
    // 使用余额变化
    usedBalanceChange(e) {
      this.$emit("usedBalanceChange", {
        totalPice: this.totalPrice - e,
      });
    },
    changeValue() {
      //增加余额
      let IncreaseBalance = this.totalPayPrice - this.totalPrice;
      this.$emit("change", {
        payList: this.payList,
        handle_school: this.handle_school,
        UsedBalance: this.UsedBalance,
        IncreaseBalance: IncreaseBalance > 0 ? IncreaseBalance : 0,
        totalPrice: this.totalPrice,
      });
    },
  },
};
</script>

<style scoped>
>>> .el-select .el-input {
  width: 160px;
}
</style>
