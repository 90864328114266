<template>
  <div class="margin-top padding bg-white">
    <border-title titleName="经办信息" />
    <div class="margin-top flex aligin-items-center">
      <div v-show="isSales" class="flex aligin-items-center">
        <span>销售来源</span>
        <div class="margin-left">
          <el-select v-model="formData.sale_source" filterable size="mini">
            <el-option
              v-for="item in saleSourceList"
              :key="item.id"
              :value="item.id"
              :label="item.sub_title"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="flex aligin-items-center margin-left-50">
        <span>经办日期</span>
        <div class="margin-left">
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :default-value="now"
            v-model="formData.handle_date"
            type="date"
            placeholder="选择日期"
            size="mini"
          />
        </div>
      </div>
      <div class="flex aligin-items-center margin-left-50">
        <span>经办人</span>
        <div class="margin-left">
          <el-select
            v-model="formData.handle_user_name"
            placeholder="请选择"
            size="mini"
            disabled
          >
            <el-option
              v-for="item in handelList"
              :key="item.value"
              :label="item.label"
              :value="item.id"
            />
          </el-select>
        </div>
      </div>
      <div v-if="isSalesperson" class="flex aligin-items-center margin-left-50">
        <span>销售人员</span>
        <div class="margin-left">
          <el-select
            v-model="formData.sale_user"
            placeholder="请输入销售员"
            size="mini"
            filterable
          >
            <el-option
              v-for="item in salesPersonOptions"
              :key="item.id"
              :label="item.full_name"
              :value="item.id"
              @remote-method="remoteMethodSaleUser"
            />
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from "@/utils/util";
export default {
  data() {
    return {
      handelList: [
        {
          value: "",
          label: "",
        },
      ],
      saleSourceList: [],
      formData: {
        sale_source: "",
        handle_date: "",
        sale_user: "",
        handle_user: "",
        handle_user_name: "",
      },
      now: "",
      salesInput: "",
      salesPersonValue: "",
      salesPersonOptions: [],
      options: [],
      dateValue: "",
    };
  },
  props: {
    // 是否显示销售来源
    isSales: {
      type: Boolean,
      default: true,
    },
    // 是否显示销售员
    isSalesperson: {
      type: Boolean,
      default: true,
    },
    initDatas:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  created() {
    this.formData.handle_date = formatTime(new Date(), "yyyy-MM-dd");
    this.initData();
  },
  mounted() {
    this.formData.handle_user = this.$store.getters.getUserInfo.id;
    this.formData.handle_user_name = this.$store.getters.getUserInfo.full_name;
    this.handelList[0].value = this.$store.getters.getUserInfo.id;
    this.handelList[0].label = this.$store.getters.getUserInfo.FullName;
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.$emit("change", this.formData);
      },
    },
    initDatas(val){
      this.formData = Object.assign({},this.formData,val);
    }
  },
  methods: {
    remoteMethodSaleUser(query) {
      this.getSalespersonList({
        KeyWord: query,
      });
    },
    initData() {
      this.getSalespersonList();
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.SaleOrigin)
        .then((res) => {
          this.saleSourceList = res.data.rows;
        });
    },
    // 获取销售人员列表
    getSalespersonList(query) {
      let obj = {
        AccountType: [8, 9],
        PageIndex: 1,
        PageSize: 20,
        ...(query || {}),
      };
      this.API.accountQuery(obj).then((res) => {
        if (res.success) {
          this.salesPersonOptions = res.data.rows;
        }
      });
    },
  },
};
</script>

<style scoped>
>>> .el-select .el-input {
  width: 160px;
}
</style>
