<template>
  <div class="bg-white padding stu-info">
    <border-title titleName="学员信息" />
    <div class="padding-top flex aligin-items-center" v-if="!studentId">
      <el-autocomplete
        style="width: 300px"
        v-model="state"
        :fetch-suggestions="querySearchAsync"
        placeholder="输入学员姓名/手机号码进行搜索"
        @select="handleSelect"
        size="mini"
      >
        <template slot-scope="{ item }">
          <div class="flex justify-content-between padding-lr width-100">
            <span style="width: 40%">{{ item.FullName }}</span>
            <span style="width: 20%">{{ isSex[item.sex] }}</span>
            <span style="width: 40%">{{ item.phone }}</span>
          </div>
        </template>
      </el-autocomplete>
      <div class="margin-left" v-if="isCreatStudents">
        <el-button type="primary" size="mini" @click="creatStudents"
          >创建学员</el-button
        >
      </div>
    </div>

    <div
      class="margin-top flex aligin-items-center bg-z padding"
      v-if="stuInfo.name != '' && isStuInfo"
    >
      <span class="padding-right">
        <span class="text-bold text-lg">{{ stuInfo.FullName }}</span>
        <span class="margin-left-sm">{{ stuInfo.sex | sexFilter }}</span>
        <span class="margin-left-sm">{{ stuInfo.phone }}</span>
        <span class="border-right padding-right">(母亲)</span>
      </span>
      <span class="border-right padding-right">
        <span class="lesson-tag">课</span>
        <span class="margin-left-sm">在读课程:</span>
        <span class="margin-left-sm">{{ currlen }}</span>
      </span>
      <span class="padding-left">
        <span class="school-tag">校</span>
        <span class="margin-left-sm">报读校区:</span>
        <span class="margin-left-sm">{{ stuInfo.school_name }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isCreatStudents: {
      type: Boolean,
      default: true
    },
    isStuInfo: {
      type: Boolean,
      default: true
    },
    studentId:{
      type:Number,
      default:0
    },
    currlength:{
      type:Number,
      default:0
    },
    queryList: {
      type: Object,
      default() {
        return {
          pageIndex: 1,
          pageSize: 20,
          KeyWord: '',
        }
      }
    }
  },
  data() {
    return {
      isSex: ["女", "男", "未知"],
      restaurants: [],
      state: "",
      timeout: null,
      currlen:0,
      stuInfo: {
        lesson: "",
        name: "",
        phone: "",
        school: "",
        sex: "",
      },
      options: [
        {
          value: "1",
          label: "1课时=59.8元",
        },
      ],
    };
  },
  watch:{
    studentId(val){
      if(val){
        this.getStudentDetail(val);
      }
    },

    currlength(val){
      if(val){
        this.currlen = (val);
      }
    },
  },
  mounted() {
    this.querySearchAsync("", (list) => {
      this.restaurants = list;
    });
  },
  methods: {
    getStudentDetail(id){
      this.API.StudentDetail(id).then(res=>{
        if(res.data && res.data.id){
          this.stuInfo = res.data;
          this.$emit("select", res.data);
        }
      })
    },
    querySearchAsync(queryString, cb) {
      let obj = this.queryList
      obj.KeyWord = queryString
      this.API.StudentList(obj).then((res) => {
        cb(res.data.rows || []);
      });
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.stuInfo = item;
      this.$emit("select", item);
    },
    creatStudents() {
      this.$Dialog.CreatStudents().then((res) => {
        // this.chooseClassResult=res || [];
        console.log(res);
      });
    },
  },
};
</script>

<style></style>
