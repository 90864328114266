<template>
  <div class="margin-top padding bg-white">
    <div class="flex">
      <border-title titleName="订单标签" />
      <div class="margin-left">
        <el-popover width="220" trigger="click" placement="top" @show="getCourseClass">
          <el-checkbox-group v-model="tagList">
            <el-checkbox v-for="item in selectTagList" :key="item.id" :label="item.sub_title" :value="item.id" class="padding-xs" />
          </el-checkbox-group>
          <div slot="reference"><el-button type="primary" size="mini">选择标签</el-button></div>
        </el-popover>
      </div>
      <div class="margin-left"><el-button size="mini" @click="addOrderTag">新建标签</el-button></div>
    </div>
  
    <div class="margin-top" v-if="tagList.length > 0">
      <div class="text-gray flex aligin-items-center">
        <div v-for="(btn, btnIndex) in tagList" :key="btnIndex">
          <div :class="{ 'margin-left': btnIndex > 0 }">
            <el-button size="mini">
              {{ btn }}
              <i class="el-icon-close" @click="delTag(btnIndex)" />
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      list:{
        type:Array,
        default:()=>{
          return [];
        }
      }
    },
    data() {
      return {
        tagList: [],
        selectTagList: [],
        listQuery: {
          HasRole: false,
          module: '',
          TotalCount: 0,
          PageIndex: 1,
          PageSize: 15
        },
      }
    },
    watch:{
      tagList:{
        deep:true,
        handler(){
          this.change();
        }
      },
      list(val){
        if(val && val.length){
          this.tagList = val;
        }
      },
    },
    created() {
      this.getCourseClass()
    },
    methods: {
      // 获取标签列表
      getCourseClass() {
        this.listQuery.module = 'OrderTag';
        this.$Dictionary.GetDictionarys(this.listQuery).then(res => {
          this.selectTagList = res.data.rows;
          this.$forceUpdate()
        });
      },
      // 新建订单标签
      addOrderTag() {
        this.$Dialog.AddOrderTag().then(res => {
          console.log(res);
        });
      },
      // 删除订单标签
      delTag(index) {
        this.tagList.splice(index, 1);
      },
      change(){
        this.$emit('change',this.tagList);
      }
    },
  }
</script>

<style scoped>
</style>
